import { Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { closeCamera, openConfirmImageUploadModal } from '../../../../../services/listerUploadVehicle';
import { GalleryIcon } from '../../../../CreateVehicle/icons/GalleryIcon';
import { useImageUploadContext } from '../../../context/ImageUploadContext';
import { useField } from 'react-final-form';
import { useGetSubSectionConfig } from '../../../../CreateVehicle/hooks/useGetSubSectionConfig';

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 14px 0 24px;
  gap: 16px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 21.09px;
  color: #fff;
`;

const SubSectionLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: rgba(153, 153, 153, 1);
`;

const MuiIcon = styled(Icon)`
  color: #fff;
`;

const GalleryIconWrapper = styled.div`
  margin-left: auto;
  display: flex;
`;

export const CameraHeader = () => {
  const dispatch = useDispatch();

  const { sectionId, subSectionId, currentImageIndex } = useImageUploadContext();

  const config = useGetSubSectionConfig(sectionId, subSectionId);

  const close = () => {
    dispatch(closeCamera());
    dispatch(openConfirmImageUploadModal(`${sectionId}.${subSectionId}`));
  };

  const {
    input: { value: label }
  } = useField(`${sectionId}.${subSectionId}.images.${currentImageIndex}.label`);

  return (
    <Wrapper>
      <MuiIcon onClick={close}>close</MuiIcon>
      <LabelWrapper>
        <SectionLabel>{config?.label}</SectionLabel>
        <SubSectionLabel>{label || 'Custom Image'}</SubSectionLabel>
      </LabelWrapper>
      <GalleryIconWrapper>
        <GalleryIcon />
      </GalleryIconWrapper>
    </Wrapper>
  );
};
