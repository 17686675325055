import { useSelector } from 'react-redux';
import { useSaveGeoMutation } from '../../services/api/list-vehicles';
import { useInterval } from 'usehooks-ts';

export const UserGeoProvider = () => {
  const user = useSelector<any, any>(state => state.user.user);

  const isLister = user && user.role === 'admin' && user.job_title === 'Lister';

  const [saveGeolocation] = useSaveGeoMutation();

  useInterval(
    () => {
      window.navigator.geolocation.getCurrentPosition(position => {
        saveGeolocation({
          lat: position.coords.latitude,
          long: position.coords.longitude,
          timestamp: position.timestamp
        });
      });
    },
    isLister ? 100000 : null
  );

  return null;
};
