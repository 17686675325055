import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import socket from './socket';
import vehicles from './vehicles';
import locations from './locations';
import auctions from './auctions';
import modals from './modals';
import utils from './utils';
import user from './user';
import users from './users';
import bids from './bids';
import financials from './financials';
import regions from './regions';
import banners from './banners';
import statistics from './statistics';
import logs from './logs';
import { connectRouter } from 'connected-react-router';
import apiSlice from '../services/api';
import auctionsSlice from '../services/auctions/liveAuctionsSlice';
import sidePanelSlice from '../services/sidePanel/sidePanel';
import mySalesSlice from '../services/mySales/salesSlice';
import myBidsSlice from '../services/byMids/myBidsSlice';
import listVehiclesSlice from '../services/listVehiclesSlice/listVehiclesSlice';
import auctionVehiclesSlice from '../services/auctionVehicles/auctionVehiclesSlice';
import offerLogSlice from '../services/offerLogSlice/offerLogSlice';
import scrollPositionsSlice from '../services/scrollPosition/scrollPosititonSlice';
import transactionsSlice from 'services/transactions/transactionsSlice';
import sellerLockSlice from 'services/sellerLock/sellerLockSlice';
import auctionsListSlice from 'services/auctions/auctionsListSlice';
import userSlice from 'services/userSlice/userSlice';
import sellerVehiclesSlice from 'services/sellerVehicles/sellerVehiclesSlice';
import salesHistorySlice from 'services/salesHistorySlice/salesHistorySlice';
import listerDashboardSlice from 'services/listerDashboard/listerDashboardSlice';
import uploadVehicleSlice from '../services/uploadVehicle/uploadVehicleSlice';
import assignVehicleImagesSlice from '../services/uploadVehicle/assignVehicleImagesSlice';
import viewVehicleSlice from '../services/viewVehicleSlice/viewVehicleSlice';
import saveImagesStateSlice from '../services/uploadVehicle/saveImagesStateSlice';
import listerUploadVehicleSlice from '../services/listerUploadVehicle';
import listerUploadVehicleSliceV2 from '../services/listerUploadVehicle/v2';
import adminVehicleHistorySlice from '../services/adminVehicleHistory/adminVehicleHistorySlice';
import sectionDragSlice from '../services/dndkit/sectionDragSlice';

const createRootReducer = history =>
  combineReducers({
    form: formReducer,
    socket,
    vehicles,
    locations,
    auctions,
    modals,
    utils,
    user,
    users,
    bids,
    financials,
    regions,
    banners,
    statistics,
    logs,
    router: connectRouter(history),
    [apiSlice.reducerPath]: apiSlice.reducer,
    liveAuctions: auctionsSlice.reducer,
    sidePanel: sidePanelSlice.reducer,
    mySales: mySalesSlice.reducer,
    myBids: myBidsSlice.reducer,
    sectionDrag: sectionDragSlice.reducer,
    adminVehicleHistory: adminVehicleHistorySlice.reducer,
    uploadVehicle: uploadVehicleSlice.reducer,
    assignVehicleImages: assignVehicleImagesSlice.reducer,
    listVehicles: listVehiclesSlice.reducer,
    listerDashboard: listerDashboardSlice.reducer,
    auctionVehicles: auctionVehiclesSlice.reducer,
    offerLog: offerLogSlice.reducer,
    scrollPositions: scrollPositionsSlice.reducer,
    transactions: transactionsSlice.reducer,
    sellerLock: sellerLockSlice.reducer,
    auctionsList: auctionsListSlice.reducer,
    authUser: userSlice.reducer,
    sellerVehicles: sellerVehiclesSlice.reducer,
    salesHistory: salesHistorySlice.reducer,
    viewVehicle: viewVehicleSlice.reducer,
    saveImagesState: saveImagesStateSlice.reducer,
    listerUploadVehicle: listerUploadVehicleSlice.reducer,
    listerUploadVehicleV2: listerUploadVehicleSliceV2.reducer
  });

export default createRootReducer;
