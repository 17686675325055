import styled from 'styled-components';
import { ExpandIcon } from '../../../components/Icons/ExpandIcon';
import { useGetSectionConfigById } from '../../CreateVehicle/hooks/useGetSubSectionConfig';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import { DragIndicator } from '@material-ui/icons';
import { CameraIcon } from '../icons/Camera';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openCamera, openOrientationPrompt } from '../../../services/listerUploadVehicle';
import { useWindowSize } from 'usehooks-ts';
import { useGetSectionsOrderQuery } from '../../../services/api/vehicle-sections';
import { useUpdateSearchParams } from '../../../hooks';
import { useState } from 'react';
import { CollapseIcon } from '../../../components/Icons/CollapseIcon';
import { CollapsibleSubSections } from './CollapsibleSubSections';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDndContext } from '@dnd-kit/core';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div<{ isExpanded: boolean; isDragging: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom-left-radius: ${props => (props.isExpanded ? '0' : '8px')};
  border-bottom-right-radius: ${props => (props.isExpanded ? '0' : '8px')};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: rgba(34, 90, 145, 1);
  padding: 8px 16px 8px 8px;
  opacity: ${props => (props.isDragging ? '0.6' : 1)};

  & > span {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    color: #fff;
  }
`;

const IconWrapper = styled.div<{ floatLeft?: boolean }>`
  display: flex;
  color: #fff;
  margin-left: ${props => (props.floatLeft ? 'auto' : 0)};
`;

type Props = {
  sectionId: keyof SectionConfig;
};

export const CollapsibleSection = ({ sectionId }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: sectionId });

  const dndContext = useDndContext();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dispatch = useDispatch();
  const config = useGetSectionConfigById(sectionId);
  const { data: sectionOrder } = useGetSectionsOrderQuery();

  const { width = 0, height = 0 } = useWindowSize();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(c => !c);
  };

  if (!config || !sectionOrder) return null;

  const isCheckboxSection = sectionId === 'title_issues' || sectionId === 'power_equipment';

  const handleTakePhotos = () => {
    const subSectionId = sectionOrder.find(e => e[0] === sectionId)?.[1]?.[0];
    if (!subSectionId) return;

    if (width < height) {
      dispatch(openOrientationPrompt(`${sectionId}.${subSectionId}`));
    } else {
      dispatch(openCamera(`${sectionId}.${subSectionId}`));
    }
  };

  return (
    <SectionWrapper ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Header isExpanded={isExpanded && !dndContext.active} isDragging={isDragging}>
        <IconWrapper onClick={toggleExpanded}>
          {isExpanded ? <CollapseIcon fillColor="#fff" /> : <ExpandIcon fillColor="#fff" />}
        </IconWrapper>
        <span>{config?.label}</span>
        {!isCheckboxSection && (
          <IconWrapper onClick={handleTakePhotos} floatLeft>
            <CameraIcon />
          </IconWrapper>
        )}
        <IconWrapper style={{ color: '#fff' }} floatLeft={isCheckboxSection} id={`activator-${sectionId}`}>
          <DragIndicator />
        </IconWrapper>
      </Header>

      {isExpanded && !dndContext.active && <CollapsibleSubSections sectionId={sectionId} />}
    </SectionWrapper>
  );
};
