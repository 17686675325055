import { createSelector } from 'reselect';
import { VehicleOptions, FormattedAmount } from '../components';
import { VehicleImage } from '../components/TableComponents';
import { BidStatus, AuctionStatus } from '../pages/Admin/VehiclesList/components';
import { userStateSelector } from './index';
import { getBidAuctionStatus } from './bids';
import {
  MyBidsNotesBlock,
  VehicleBidInfoBlock,
  MyBidsCurrentBidBlock,
  MyBidsPlaceBidBlock
} from '../pages/Buyer/BidsList/components/tableBlocks';
import { VehicleOptionsBlock } from '../pages/Seller/VehicleSales/components/tableBlocks';
import { Delimiter } from '../components/Table/common';
import MyBidEditModal from '../pages/Buyer/BidsList/components/MyBidEditModal';
import { SendOfferModal } from '../components/Offers';
import { AcceptOfferModal } from '../components/Offers/AcceptOfferModal';
import { BuyerModals } from '../pages/Buyer/BidsList/components/tableBlocks/BuyerModals';
import { TabletBottomBlock } from '../pages/Buyer/BidsList/components/tableBlocks/TabletBottomBlock';
import { MobileHorizontalTableBottomBlock } from '../pages/Buyer/BidsList/components/tableBlocks/MobileHorizontalTableBottomBlock';

export const adminVehiclesSelector = state => state.vehicles.vehiclesAdmin.rows;

export const adminConvertedVehicles = createSelector([adminVehiclesSelector], vehicles =>
  vehicles.map(item => {
    const highBid = item.highest_bid;
    let proxyBid = item.proxy_bid;

    if (item.proxy_bid) {
      proxyBid = item.proxy_bid.amount;
    }

    if (highBid && proxyBid < highBid.amount) {
      proxyBid = highBid.amount;
    }

    return {
      ...item,
      auction_id_view: item.auction?.slug ?? '-',
      seller: item.user?.dealership_name,
      image: VehicleImage(item),
      reviewer: item.reviewer?.options?.name_for_bio || item.reviewer?.first_name || 'N/A',
      vehicle_status: <BidStatus vehicle={item} status={item.status} />,
      auction_status: <AuctionStatus status={(item.auction || {}).status || 'N/A'} />,
      details: <VehicleOptions currentVehicle={item} />,
      high_bidder: (highBid && highBid.amount && highBid.bidder.dealership_name) || 'N/A',
      high_bid_id: (highBid && highBid.id) || 'N/A',
      proxy: FormattedAmount({ amount: proxyBid || 0 }),
      ending_bid: FormattedAmount({ amount: (highBid && highBid.amount) || 0 }),
      lister: item.lister?.options?.name_for_bio ?? 'N/A'
    };
  })
);

export const buyerBidsSelector = state => state.bids.itemsData.rows;

export const buyerBidsConvertedVehicles = vehicles => {
  if (!vehicles) return [];
  return vehicles.map(vehicle => {
    return {
      ...vehicle,
      details: <VehicleOptionsBlock currentVehicle={vehicle} includeOrderIndex />,
      secondary_info: <VehicleBidInfoBlock currentVehicle={vehicle} />,
      notes: <MyBidsNotesBlock currentVehicle={vehicle} />,
      delimiter: <Delimiter />,
      current_bid: <MyBidsCurrentBidBlock currentVehicle={vehicle} />,
      place_bid: <MyBidsPlaceBidBlock currentVehicle={vehicle} />,
      modals: <BuyerModals currentVehicle={vehicle} />,
      tablet_place_bid: <TabletBottomBlock currentVehicle={vehicle} />,
      mobile_place_bid: <MobileHorizontalTableBottomBlock currentVehicle={vehicle} />
    };
  });
};
