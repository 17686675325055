/* eslint-disable no-else-return */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { Divider } from '@material-ui/core';
import styled from 'styled-components';
import BiddingComponent from '../RoleRestrictedSection/BuyerRestrictionSection/BiddingComponent';
import {
  VEHICLE_STATUSES,
  AUCTION_STATUSES,
  BID_INCREMENT,
  ADMIN_VEHICLE_STATUS,
  BUYER_VEHICLE_STATUS
} from '../../../../constants';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../hooks';
import { auctionOfferStatuses } from '../../../../utils/statusCalculator';
import OfferBiddingComponent from '../RoleRestrictedSection/BuyerRestrictionSection/OfferBiddingComponent';
import { getHighestBid, getMinBitAmount } from '../../../../utils/vehicle';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const BuyerBiddingSection = ({ currentVehicle, currentUser }) => {
  let blockToRender = null;
  const highestBid = getHighestBid(currentVehicle);

  const isBuyerProxyBidder = currentUser.id === (currentVehicle.proxy_bid || {}).user_id;
  const isProxyBidHighestBid = (currentVehicle.proxy_bid || {}).amount > highestBid.amount;
  const isAuctionActive = currentVehicle.auction.status === AUCTION_STATUSES.ACTIVE;
  const isVehicleActive = currentVehicle.status === VEHICLE_STATUSES.ACTIVE;
  const currentTime = moment.utc();
  const isVehicleTimerEnded =
    currentVehicle.date_end !== null &&
    moment(currentVehicle.date_end)
      .utc()
      .isBefore(currentTime);

  const user = useSelector(state => state.user.user);

  const offerAuction = useNoSelectorVehicleOfferAuctionById(currentVehicle.id);
  const status = auctionOfferStatuses(currentVehicle, offerAuction, user);

  if (
    isAuctionActive &&
    !!offerAuction &&
    [BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER, BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS].includes(
      status
    )
  ) {
    blockToRender = (
      <OfferBiddingComponent
        initialValues={{
          amount: ''
        }}
        bidIncrement={BID_INCREMENT}
        vehicleId={currentVehicle.id}
      />
    );
  } else if (isVehicleTimerEnded && isVehicleActive && !currentVehicle.buyer_id) {
    return null;
  } else if (isVehicleTimerEnded && isVehicleActive && currentVehicle.buyer_id) {
    return null;
  } else if (isVehicleActive && isBuyerProxyBidder && isAuctionActive && isProxyBidHighestBid) {
    blockToRender = (
      <BiddingComponent
        initialValues={{
          amount: ''
        }}
        minAmount={getMinBitAmount(currentVehicle, currentUser)}
        bidIncrement={BID_INCREMENT}
        vehicleId={currentVehicle.id}
        isProxyBid
      />
    );
  } else if (isVehicleActive && isAuctionActive) {
    blockToRender = (
      <BiddingComponent
        initialValues={{
          amount: ''
        }}
        minAmount={getMinBitAmount(currentVehicle, currentUser)}
        bidIncrement={BID_INCREMENT}
        vehicleId={currentVehicle.id}
      />
    );
  } else if (currentVehicle.status === VEHICLE_STATUSES.AWARDED) {
    return null;
  } else if (currentVehicle.status === VEHICLE_STATUSES.PULLED) {
    return null;
  }
  return (
    <>
      <StyledDivider />
      {blockToRender}
    </>
  );
};

export default connect(state => ({
  currentVehicle: state.vehicles.selectedVehicle.data,
  currentUser: state.user.user
}))(BuyerBiddingSection);
