import { CollapsibleSections } from '../sections/CollapsibleSections';
import { ToggleOrientationPrompt } from '../components/ToggleOrientation';
import { CameraComponent } from '../components/Camera';
import { ConfirmSectionModal } from '../components/ConfirmSectionModal';
import { useOrientationPromptOpen } from '../../../services/listerUploadVehicle';

export const SectionsListView = () => {
  const isOrientationPromptOpen = useOrientationPromptOpen();
  return (
    <>
      <CollapsibleSections />
      {isOrientationPromptOpen && <ToggleOrientationPrompt />}
      <ConfirmSectionModal />
      <CameraComponent />
    </>
  );
};
