import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

type ListerUploadVehicleState = {
  openedQuestionsDialog: number | null;
}

const initialState: ListerUploadVehicleState = {
  openedQuestionsDialog: null,
};

const listerUploadVehicleV2Slice = createSlice({
  name: 'listerUploadVehicleV2',
  initialState,
  reducers: {
    openQuestionsDialog: (state, action: PayloadAction<number>) => {
      state.openedQuestionsDialog = action.payload
    },
    closeQuestionsDialog: (state) => {
      state.openedQuestionsDialog = null
    },
  }
});

export const useOpenedQuestionsDialog = () => {
  const openedQuestionsDialog = useSelector<{ listerUploadVehicleV2: ListerUploadVehicleState }, number | null>((state) => state.listerUploadVehicleV2.openedQuestionsDialog)
  return openedQuestionsDialog
}

export const { openQuestionsDialog, closeQuestionsDialog } = listerUploadVehicleV2Slice.actions
export default listerUploadVehicleV2Slice;
