import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { modalsToggle } from '../../../../../actions';
import { SectionWrapper, SectionTitle } from '../index';
import ViewAllBidsModal from './components/ViewAllBidsModal';
import { useParams } from 'react-router-dom';

const styles = {
  marginBottom: {
    marginBottom: '24px'
  },
  tableRow: {
    paddingBottom: '0'
  },
  tableContainer: {
    maxHeight: '140px',
    overflowY: 'scroll'
  }
};

const Wrapper = styled.div`
  padding: 20px 16px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(33, 33, 33, 1);

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 16.41px;
  }
`;

const ViewAllButton = styled.div`
  color: rgba(57, 148, 222, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;

  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

const FirstBid = styled.span`
  color: #009f0f;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const SecondBid = styled.span`
  color: #d50808;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const ViewBidsSection = styled.div`
  display: flex;
  align-items: center;
  max-height: 10px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const TextInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(102, 102, 102, 1);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${props => (props.alignLeft ? 'end' : 'start')};
  margin-left: ${props => (props.alignLeft ? 'auto' : 0)};

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const ArrowDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 15.6016L5.97501 9.60156H18.025L12 15.6016Z" fill="#757575" />
  </svg>
);

const BidsTable = ({ classes, bids }) =>
  bids.length ? (
    <Grid style={{ marginTop: '16px' }} container direction="column">
      {bids
        .sort((a, b) => {
          if (a.bid >= b.bid && a.id > b.id) {
            return -1;
          }
          if (b.bid >= a.bid && b.id > a.id) {
            return 1;
          }
          return 0;
        })
        .map((bid, index) => (
          <Grid wrap="nowrap" container item xs={12} key={index}>
            <Grid style={{ maxWidth: '100px' }} item xs={6} sm={4}>
              <Grid container alignItems="centers" style={{ gap: '4px' }}>
                <TextInfo>
                  <NumberFormat displayType="text" prefix="$" value={bid.bid} thousandSeparator />
                </TextInfo>
                {index === 0 && <FirstBid> (1st)</FirstBid>}
                {index === 1 && <SecondBid> (2nd)</SecondBid>}
              </Grid>
            </Grid>
            <Grid style={{ flexBasis: '100%' }} item xs={6} sm={4}>
              <TextInfo title={bid.bidder}>{`by ${bid.bidder}`}</TextInfo>
            </Grid>
            <Grid style={{ marginLeft: 'auto' }} item xs={12} sm={4}>
              <TextInfo alignLeft>{bid.date}</TextInfo>
            </Grid>
          </Grid>
        ))}
    </Grid>
  ) : null;

const labelsConfig = {
  bids: {
    header: 'Bid History',
    itemType: 'Bids',
    modalId: 'view-all-bids',
    noDataText: 'There are no bids yet.'
  },
  offers: {
    header: 'Offers History',
    itemType: 'Offers',
    modalId: 'view-all-offers',
    noDataText: 'There are no offers yet.'
  }
};

const BidHistorySection = ({ classes, bids, proxyBid, toggleModal, type, initiallyCollapsed }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  React.useEffect(() => {
    if (initiallyCollapsed) {
      setIsExpanded(false);
    }
  }, [initiallyCollapsed]);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const { header, itemType, modalId, noDataText } = labelsConfig[type];

  const { vehicleId } = useParams();

  return (
    <Wrapper>
      <Grid container justify="space-between" alignItems="center" item xs={12}>
        <Title withMargin={bids.length > 0 && isExpanded}>
          {header}
          {bids.length ? ` (${bids.length} ${itemType})` : null}
        </Title>
        <ViewBidsSection>
          {bids.length ? <ViewAllButton onClick={() => toggleModal(modalId)}>View All</ViewAllButton> : null}
          {bids.length > 0 && (
            <IconWrapper onClick={toggleExpanded}>
              <ArrowDownIcon />
            </IconWrapper>
          )}
        </ViewBidsSection>
      </Grid>
      {isExpanded &&
        (bids.length ? (
          <Grid item xs={12} classes={{ item: classes.tableContainer }}>
            <BidsTable classes={classes} bids={bids} />
          </Grid>
        ) : (
          <Grid item xs={12} style={{ color: 'rgb(97, 97, 97)' }}>
            {noDataText}
          </Grid>
        ))}
      <ViewAllBidsModal
        vehicleId={Number(vehicleId)}
        bids={bids}
        type={type}
        proxyBid={proxyBid}
        form={`adminEditBidModalForm-${type}`}
      />
    </Wrapper>
  );
};
const mapDispatchToProps = dispatch => ({
  toggleModal: modalId => dispatch(modalsToggle(modalId))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(BidHistorySection));
