import { useDispatch } from 'react-redux';
import { Button, Grid, Divider } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { modalsToggle } from '../../../../../actions';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import { useOfferAuctionBidsInfo } from '../../../../../hooks';
import { useAcceptBidMutation, useAcceptPrivateOfferMutation } from 'services/api/offer-auction';
import { OfferAuctionStatus } from '../../../../../types/vehicle';
import { ActionButton } from '../../../../../components/ActionButton';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const TopOfferWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  width: 100%;

  & > button {
  }

  @media (max-width: 425px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    & > button {
      width: 100%;
    }
  }
`;

const ControlButton = styled(Button)`
  border-radius: 6px;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

const AdminTopOfferBlock = ({ offerAuction }) => {
  const dispatch = useDispatch();
  const handleAccept = () => dispatch(modalsToggle(`acceptTopOffer`));
  const { highestBidOffer } = useOfferAuctionBidsInfo(offerAuction);

  const buyerAmount = (() => {
    if (offerAuction?.status !== OfferAuctionStatus.PRIVATE_BIDS) {
      return 0;
    }

    return offerAuction?.privateOffers?.find(el => el.sender.role === 'buyer')?.amount ?? 0;
  })();

  const [acceptBid] = useAcceptBidMutation();
  const [acceptOffer] = useAcceptPrivateOfferMutation();

  const offerAmount = Math.max(buyerAmount, highestBidOffer.amount);
  const offerFrom = (() => {
    if (buyerAmount > highestBidOffer.amount) {
      return offerAuction?.topBidder?.dealership_name ?? '';
    }
    return highestBidOffer.bidder?.dealership_name ?? '';
  })();

  const handleAcceptBid = () => {
    if (buyerAmount >= highestBidOffer.amount) {
      acceptOffer({ vehicleId: offerAuction.vehicle_id });
    } else {
      acceptBid({ offer_id: highestBidOffer.id, vehicle_id: offerAuction.vehicle_id });
    }
    dispatch(modalsToggle(`acceptTopOffer`));
  };

  if (offerAmount === 0 || highestBidOffer.bidder.is_bot) return null;

  return (
    <>
      <TopOfferWrapper>
        <Grid style={{ paddingRight: 32 }} item xs={12} sm={8}>
          <span>Top Offer — </span>
          <NumberFormat value={offerAmount} displayType="text" thousandSeparator prefix="$" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ActionButton colorVariant="accept" onClick={handleAccept}>
            Accept Offer
          </ActionButton>
        </Grid>
      </TopOfferWrapper>
      <Grid item xs={12}>
        <StyledDivider />
      </Grid>
      <ConfirmModal
        modalId={'acceptTopOffer'}
        title={
          <>
            You are about to accept the offer of{' '}
            <NumberFormat displayType="text" prefix="$" value={offerAmount} thousandSeparator />
            from {offerFrom}
            {/* {` ${highestBidOffer.bidder.dealership_name ?? ''}`} */}
          </>
        }
        handleSubmit={handleAcceptBid}
      />
    </>
  );
};

export default AdminTopOfferBlock;
