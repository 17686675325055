import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Divider } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { modalsToggle, auctionVehiclesSetModal } from '../../../../../actions';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import { ActionButton } from '../../../../../components/ActionButton';
import { legacyOfferAuctionSelector } from 'services/api/offer-auction';
import moment from 'moment';

const modalTitle = 'confirmApproveModal';
const chargeModalTitle = 'auctionDetailAwardBuyerModal';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 8px 32px 8px 8px;
  font-weight: 500;
  padding-left: 0;
`;

const ApproveButton = styled(Button)`
  border-radius: 6px;
  min-width: 200px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

class HighestBidBlock extends Component {
  state = {
    highestBid: null
  };

  componentDidMount() {
    const { currentVehicle, setModal } = this.props;

    setModal(currentVehicle);
    const highestBid = currentVehicle.bids.reduce(
      (acc, bid) => {
        if (bid.amount >= acc.amount && bid.id > acc.id) {
          return bid;
        }
        return acc;
      },
      { amount: 0, id: 0 }
    );
    this.setState({ highestBid });
  }

  handleClickApprove = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalTitle);
  };

  handleApprove = () => {
    const { modalsToggle: toggle, setModal, vehicle } = this.props;
    const { highestBid } = this.state;
    const preSelectedBuyer = { value: highestBid.bidder.id, label: highestBid.bidder.dealership_name };
    const predefinedAwardPrice = highestBid.amount;
    setModal({ ...vehicle, preSelectedBuyer, predefinedAwardPrice });
    toggle(modalTitle);
    toggle(chargeModalTitle);
  };

  render() {
    const { currentVehicle, loading, user, offerAuction } = this.props;
    const { highestBid } = this.state;
    if (
      !highestBid ||
      !highestBid.bidder ||
      highestBid.bidder.is_bot ||
      (!currentVehicle && !currentVehicle.proxy_bid)
    ) {
      return null;
    }
    const proxyBid = currentVehicle.proxy_bid || { amount: 0 };
    const isProxyHigherThanCurrentBid = proxyBid.amount >= highestBid.amount;
    const currentTime = moment.utc();
    const proxyOfferBid = offerAuction?.offerProxyBid;
    const showProxyOffer =
      proxyOfferBid && currentVehicle?.date_end && moment.utc(currentVehicle.date_end).isBefore(currentTime);
    return (
      <Fragment>
        <Grid container item xs={12}>
          <StyledGrid item xs={12} sm={8}>
            <div>
              <span>Highest Bid — </span>
              <NumberFormat value={highestBid.amount} displayType="text" thousandSeparator prefix="$" />
              <span>{` (${highestBid.bidder.dealership_name})`}</span>
            </div>
          </StyledGrid>
          <Grid item xs={12} sm={4}>
            <ActionButton onClick={this.handleClickApprove} fullWidth variant="contained" disabled={loading}>
              Approve
            </ActionButton>
          </Grid>
          <Grid item xs={12}>
            <StyledDivider />
          </Grid>
          {isProxyHigherThanCurrentBid && !showProxyOffer && (
            <>
              <StyledGrid item xs={12} sm={8}>
                <div>
                  <span>Proxy Bid — </span>
                  <NumberFormat value={proxyBid.amount} displayType="text" thousandSeparator prefix="$" />
                  <span>
                    {highestBid.bidder.user_id === proxyBid.user_id ? ` (${highestBid.bidder.dealership_name})` : ''}
                  </span>
                </div>
              </StyledGrid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
            </>
          )}
          {showProxyOffer && (
            <>
              <StyledGrid item xs={12} sm={8}>
                <div>
                  <span>Proxy Offer — </span>
                  <NumberFormat value={proxyOfferBid?.amount} displayType="text" thousandSeparator prefix="$" />
                  {/* <span>
                    {offerAuction?.offerBids?.[0]?.user_id === proxyOfferBid.user_id
                      ? ` (${proxyOfferBid.bidder.dealership_name})`
                      : ''}
                  </span> */}
                </div>
              </StyledGrid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
            </>
          )}
        </Grid>
        <ConfirmModal
          modalId={modalTitle}
          title={
            <>
              You are about to approve the high bid of{' '}
              <NumberFormat displayType="text" prefix="$" value={highestBid.amount} thousandSeparator /> from{' '}
              {highestBid.bidder.dealership_name}.
            </>
          }
          handleSubmit={() => {
            this.handleApprove();
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.vehicles.loading,
  auctionVehicle: state.auctions.auctionVehiclesModal,
  currentVehicle: state.vehicles.selectedVehicle.data,
  offerAuction: legacyOfferAuctionSelector(state, state.vehicles.selectedVehicle.data?.id),
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  modalsToggle: key => dispatch(modalsToggle(key)),
  setModal: data => dispatch(auctionVehiclesSetModal(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(HighestBidBlock);
