import React from 'react';
import { Typography, ButtonBase, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PageHeader } from '../../../../components';
import { filterVehiclesAdmin } from '../../../../actions/vehicles';

const StyledTypography = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    padding: 23px 13px;
  }
`;

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: ${props => (props.mobile ? 0 : '16px')};
`;

const Header = ({ refreshList, perPage, setPage, mobile }) => (
  <StyledPageHeader mobile={mobile}>
    <StyledTypography variant="title">Manage Vehicles</StyledTypography>
    <span className="flex-grow" />
    <ButtonBase
      onClick={() => {
        console.log('clicked')
        setPage(1);
        refreshList({ params: { limit: perPage, offset: 1 } });
        setPage(2);
      }}
    >
      <Icon>refresh</Icon>
    </ButtonBase>
  </StyledPageHeader>
);

const mapDispatchToProps = dispatch => ({
  refreshList: data => dispatch(filterVehiclesAdmin(data).request)
});

export default connect(
  state => ({
    auctionStatus: state.vehicles.auctionStatus
  }),
  mapDispatchToProps
)(Header);
