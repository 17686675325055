import React from 'react';
import styled from 'styled-components';
import { GalleryIcon } from '../../icons/GalleryIcon';
import { Button, Dialog, Icon } from '@material-ui/core';
import {
  closeMobileImagesDialog,
  openCameraDialog,
  useOpenedMobileImagesDialog
} from '../../../../services/uploadVehicle/uploadVehicleSlice';
import { useDispatch } from 'react-redux';
import { Close } from '@material-ui/icons';
import { TakePhotoImage } from '../../icons/TakePhotoImage';
import { useHandleImageChange } from '../../hooks/useHandleImageChange';
import { LoaderModal } from '../../../../components';
import { useFieldArray } from 'react-final-form-arrays';
import { openAssignImageDialog } from '../../../../services/uploadVehicle/assignVehicleImagesSlice';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 16px;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDialog = styled(Dialog)`
  .mobile-image-upload {
    border-radius: 20px;
    background: #fff;
    height: 164px;
    margin: 0 18px;
    width: 100%;
    max-height: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  border-radius: 6px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
`;

const StyledButtonLabel = styled.label`
  border-radius: 6px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
  background: #005a96;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 8px 16px;
`;

const ButtonLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin-left: 10px;
`;

export const MobileAddImagesDialog = () => {
  const dispatch = useDispatch();

  const openedDialogName = useOpenedMobileImagesDialog();

  const close = () => {
    dispatch(closeMobileImagesDialog());
  };

  return (
    <StyledDialog
      onClose={close}
      disableBackdropClick
      open={!!openedDialogName}
      classes={{ paperScrollPaper: 'mobile-image-upload' }}
    >
      {openedDialogName && <MobileAddImagesDialogContent modalId={openedDialogName} />}
    </StyledDialog>
  );
};

type Props = {
  modalId: string;
};

const MobileAddImagesDialogContent = ({ modalId }: Props) => {
  const [fieldName, type] = modalId.split('-');
  const isMulti = type === 'multi';

  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeMobileImagesDialog());
  };

  const openCamera = () => dispatch(openCameraDialog(modalId));

  return (
    <Wrapper>
      <HeaderRow>
        <Title>{isMulti ? 'Add up to 10 Photos' : 'Add 1 Photo'}</Title>
        <Icon onClick={close}>
          <Close />
        </Icon>
      </HeaderRow>
      <ButtonRow>
        <GalleryUpload isMulti={isMulti} fieldName={fieldName} />
        <StyledButton onClick={openCamera} type="button" variant="contained" color="primary">
          <Icon>
            <TakePhotoImage />
          </Icon>
          <ButtonLabel>Take Photo</ButtonLabel>
        </StyledButton>
      </ButtonRow>
    </Wrapper>
  );
};

type ButtonProps = {
  fieldName: string;
  isMulti: boolean;
};

const GalleryUpload = ({ fieldName, isMulti }: ButtonProps) => {
  const { handleImageChange, imageLoading } = useHandleImageChange(fieldName);

  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeMobileImagesDialog());
  };

  const handleSingleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) return;
    await handleImageChange(e.target?.files?.[0]);
    close();
  };

  const { fields } = useFieldArray(`${fieldName}.images`);

  const handleMultiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target?.files || [];
    dispatch(openAssignImageDialog({ sectionId: fieldName, localImages: files, formImages: fields.value }));
    close();
  };

  return (
    <StyledButtonLabel htmlFor={`${fieldName}-upload`}>
      <Icon>
        <GalleryIcon />
      </Icon>
      <ButtonLabel>From Gallery</ButtonLabel>
      <input
        id={`${fieldName}-upload`}
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        multiple={isMulti}
        onChange={isMulti ? handleMultiChange : handleSingleChange}
      />
      <LoaderModal modalId={fieldName} forceOpen={imageLoading} loaderText="Saving image..." />
    </StyledButtonLabel>
  );
};
