import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Header, Filter } from './components';
import { Table } from '../../../components';
import { buyerBidsConvertedVehicles } from '../../../selectors/tables';
import { BidInfoLabel } from './components/tableBlocks';
import { useGetMyBidsQuery } from 'services/api/my-bids';
import { setPage } from '../../../services/byMids/myBidsSlice';
import { useWindowSize } from 'usehooks-ts';

const StyledTableWrapper = styled.div`
  div td:first-child {
    width: 80px;
  }

  div td {
    vertical-align: ${({ isPortraitView }) => (isPortraitView ? 'top' : 'middle')};
  }

  tbody > div {
    height: ${({ isPortraitView, isMobilePortraitView }) => {
      if (isMobilePortraitView) return '240px';
      if (isPortraitView) return '180px';
      return '160px';
    }};
  }

  table {
    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      width: auto;
      align-items: center;
    }
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: -6px;
  left: -4px;
  z-index: 888;

  @media (max-width: 576px) {
    right: -4px;
    left: unset;
  }
`;

const BidInfoIconComponent = ({ id, item }) => (
  <IconWrapper>
    <BidInfoLabel id={id} item={item} />
  </IconWrapper>
);

const columns = [
  { title: '', key: 'details' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'secondary_info' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'notes' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'current_bid' },
  { title: '', key: 'place_bid' },
  { title: '', key: 'modals' }
];

const tabletHorizontalColumns = [
  { title: '', key: 'details' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'secondary_info' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'current_bid' }
];

const footerColumns = [{ title: '', key: 'tablet_place_bid' }];
const mobileFooterColumns = [{ title: '', key: 'mobile_place_bid', size: 'lg' }];

const BidsListFC = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);

  const { page, perPage, filters } = useSelector(state => state.myBids);

  const { data, isFetching } = useGetMyBidsQuery({
    limit: perPage,
    offset: page,
    ...filters
  });

  const vehicles = useMemo(() => data?.rows?.ids?.map(id => data?.rows?.entities?.[id]) ?? [], [data]);
  const vehiclesCount = useMemo(() => data?.count ?? 0, [data]);

  const loadMore = () => {
    if (perPage * page > vehiclesCount) return;
    dispatch(setPage(page + 1));
  };

  const icons = [BidInfoIconComponent];

  const { width = 0, height = 0 } = useWindowSize();

  const isPortraitView = width > height && width < 1200;
  const isMobilePortraitView = isPortraitView && width < 810;

  const columnsHead = (() => {
    if (isMobilePortraitView) return tabletHorizontalColumns.slice(0, 4);
    if (isPortraitView) return tabletHorizontalColumns;
    return columns;
  })();

  const isCarDeclined = item => item.status === 'awarded' && item.buyer_id !== user.id;

  const getRowBackgroundColor = item => {
    if (item.status === 'pulled' || isCarDeclined(item)) {
      return '#B0B0B04D';
    }

    return '#fff';
  };

  const footer = (() => {
    if (isMobilePortraitView) return mobileFooterColumns;
    if (isPortraitView) return footerColumns;
    return [];
  })();

  return (
    <div>
      <Header />
      <div className="pageWrapper">
        <Filter />
        <StyledTableWrapper isPortraitView={isPortraitView} isMobilePortraitView={isMobilePortraitView}>
          <Table
            withMobileView
            icons={icons}
            columnsHead={columnsHead}
            footerColumns={footer}
            columnsData={buyerBidsConvertedVehicles(vehicles)}
            rowsCount={vehiclesCount}
            buttons={null}
            page={page}
            rowsPerPage={perPage}
            loadMore={loadMore}
            loading={isFetching}
            rowElement="div"
            getRowBackgroundColor={getRowBackgroundColor}
            noDataText={<>No bids found</>}
          />
        </StyledTableWrapper>
      </div>
    </div>
  );
};

export default BidsListFC;
