import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Grid, Divider } from '@material-ui/core';
import moment from 'moment';
import { ADMIN_VEHICLE_STATUS, VEHICLE_STATUSES } from '../../../../constants';
import AwardBuyerBlock from './AwardBuyerBlock';
import PullVehicleBlock from './PullVehicleBlock';
import HighestBidBlock from './HighestBidBlock';
import AcceptedHighestBidBlock from './AcceptedHighestBidBlock';
import CounterBidBlock from './CounterBidBlock';
import AwardedBlock from './AwardedBlock';
import DeactivatedBlock from './DeactivatedBlock';
import { ReservePriceSection } from '../components';
import styled from 'styled-components';
import {
  useNoSelectorVehicleOfferAuctionById,
  usePublicOfferAmount,
  useVehicleOfferAuctionById
} from '../../../../hooks';
import { auctionOfferStatuses } from '../../../../utils/statusCalculator';
import PublicOfferBlock from './PublicOffersBlock';
import { OfferHistory } from '../../../../components/Offers';
import { useOfferAuctionBidsInfo } from '../../../../hooks';
import AdminTopOfferBlock from './TopOfferBlock';
import SellerAcceptedPublicOfferBlock from './PublicOffersBlock/SellerAcceptedPublicOfferBlock';
import { StopNegotiationBlock } from './StopNegotiationBLock';
import { OfferAuctionStatus } from 'types/vehicle';

const ReservePriceSectionWrapper = styled.div``;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const AdminControlsBlock = ({ ...props }) => {
  const { vehicle } = props;
  const currentTime = moment.utc();
  const isVehicleTimerEnded =
    vehicle.end_time_spacing !== null &&
    moment
      .utc(vehicle.auction.date_end)
      .add(vehicle.end_time_spacing, 'seconds')
      .isBefore(currentTime);

  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle.id);
  const user = useSelector(state => state.user.user);
  const status = auctionOfferStatuses(vehicle, offerAuction, user);

  const { highestBidOffer } = useOfferAuctionBidsInfo;

  if (status === ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED) {
    return (
      <Grid container>
        <PullVehicleBlock isVehicleTimerEnded={isVehicleTimerEnded} {...props} />
        {vehicle.buyer_id ? <AcceptedHighestBidBlock {...props} /> : <HighestBidBlock {...props} />}
        <ReservePriceSection vehicle={vehicle} />
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
        <SellerAcceptedPublicOfferBlock offerAuction={offerAuction} vehicle={vehicle} />
        <OfferHistory offerAuction={offerAuction} withDivider />
        <AwardBuyerBlock {...props} />
      </Grid>
    );
  }

  if (
    status === ADMIN_VEHICLE_STATUS.SELLER_COUNTERED ||
    status === ADMIN_VEHICLE_STATUS.BUYER_NOT_COUNTERED ||
    status === ADMIN_VEHICLE_STATUS.PUBLIC_ADMIN_COUNTER_SENT ||
    status === ADMIN_VEHICLE_STATUS.SELLER_NOT_COUNTERED
  ) {
    return (
      <Grid container>
        <PullVehicleBlock isVehicleTimerEnded={isVehicleTimerEnded} {...props} />
        <HighestBidBlock {...props} />
        <ReservePriceSection vehicle={vehicle} />
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
        <PublicOfferBlock offerAuction={offerAuction} vehicle={vehicle} />
        <OfferHistory offerAuction={offerAuction} withDivider />
        <AwardBuyerBlock {...props} />
      </Grid>
    );
  }

  return (
    <Grid container>
      {![VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) && (
        <PullVehicleBlock isVehicleTimerEnded={isVehicleTimerEnded} {...props} />
      )}
      {[
        ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT,
        ADMIN_VEHICLE_STATUS.SELLER_COUNTER_SENT,
        ADMIN_VEHICLE_STATUS.ADMIN_COUNTER_SENT
      ].includes(status) &&
        offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS &&
        offerAuction?.privateOffers?.length > 0 && <StopNegotiationBlock vehicle={vehicle} />}
      {![VEHICLE_STATUSES.PULLED, VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) &&
        isVehicleTimerEnded &&
        !vehicle.buyer_id && <HighestBidBlock {...props} />}
      <ReservePriceSection vehicle={vehicle} />
      <Grid item xs={12}>
        <StyledDivider />
      </Grid>
      {![VEHICLE_STATUSES.PULLED, VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) &&
        isVehicleTimerEnded &&
        vehicle.buyer_id && <AcceptedHighestBidBlock {...props} />}
      {vehicle.status === VEHICLE_STATUSES.ACTIVE && <AdminTopOfferBlock offerAuction={offerAuction} />}
      {![VEHICLE_STATUSES.PULLED, VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) &&
        isVehicleTimerEnded && <CounterBidBlock {...props} isVehicleTimerEnded={isVehicleTimerEnded} />}
      {![VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) && (
        <AwardBuyerBlock {...props} />
      )}
      {vehicle.status === VEHICLE_STATUSES.AWARDED && <AwardedBlock {...props} offerAuction={offerAuction} />}
      {vehicle.status === VEHICLE_STATUSES.DEACTIVATED && <DeactivatedBlock {...props} />}
    </Grid>
  );
};

const mapStateToProps = state => ({
  currentAuction: state.vehicles.selectedVehicle.data.auction || {}
});

export default connect(mapStateToProps)(AdminControlsBlock);
