import styled from 'styled-components';
import { Header } from './components/header';
import { Form } from 'react-final-form';
import { Vehicle } from '../../types/vehicle';
import arrayMutators from 'final-form-arrays';
import { useSearchParams } from '../../hooks';
import { SectionsListView } from './views/SectionsListView';
import { SectionUploadSuccessView } from './views/SectionUploadSuccess';
import { SectionExpandedView } from './views/SectionExpaned';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { defaultSectionOrder, useGetSectionConfigQuery } from '../../services/api/vehicle-sections';
import { useGetVehicleByIdQuery } from '../../services/api/upload-vehicle';
import { SectionConfig } from '../../services/api/vehicle-sections/types';

const Wrapper = styled.div`
  padding: 16px;
  padding-top: 72px;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

const useCreateSectionsFieldsFn = () => {
  const { data: sectionsConfig } = useGetSectionConfigQuery();
  const { id } = useParams<{ id: string }>();
  const { data: existingVehicle } = useGetVehicleByIdQuery(Number(id), { skip: !id });

  return useCallback(() => {
    if (!sectionsConfig) return {};

    const createAdditionalInteriorSections = () => {
      const config = sectionsConfig.interior.subsections.interior_functional.additionalSections;
      const result: Record<string, any> = {};
      for (const key of Object.keys(config)) {
        const section =
          existingVehicle?.vehicleSections.find(
            e => e.section.name === 'interior' && e.section.sub_section_name === key
          ) || null;

        result[key] = {
          grade: section?.grade?.slug || null
        };
      }

      return result;
    };

    const createSubsections = (name: keyof SectionConfig, subSections: string[]) => {
      if (subSections.length === 0) {
        const section = existingVehicle?.vehicleSections.find(e => e.section.name === name) || null;
        const sectionConfig = sectionsConfig[name];
        return {
          [name]: {
            grade: section?.grade?.slug || null,
            checkboxes: []
          }
        };
      }

      const subsectionMap: Record<string, any> = {};

      for (const subSectionName of subSections) {
        const section =
          existingVehicle?.vehicleSections.find(
            e => e.section.name === name && e.section.sub_section_name === subSectionName
          ) || null;

        const sectionConfig = sectionsConfig[name].subsections[subSectionName];
        subsectionMap[subSectionName] = {
          images: section?.images || [],
          grade: section?.grade?.slug || null,
          notes: section?.notes || null,
          audio_url: section?.audio_url || null
        };
      }

      return {
        [name]: subsectionMap
      };
    };

    let resultMap: Record<string, any> = {};

    for (const [section, subSection] of defaultSectionOrder) {
      if (section === 'dealer_comments') {
        continue;
      }
      resultMap = { ...resultMap, ...createSubsections(section, subSection) };
    }

    const additionalInteriorSections = createAdditionalInteriorSections();
    resultMap.interior = { ...resultMap.interior, ...additionalInteriorSections };

    return resultMap;
  }, [sectionsConfig, id, existingVehicle]);
};

const viewMap: Record<string, () => JSX.Element | null> = {
  sections_list: SectionsListView,
  section_upload_success: SectionUploadSuccessView,
  section_expanded_view: SectionExpandedView
};

const handleSubmit = () => {};

const ListerVehicleUpload = () => {
  const existingVehicle = (null as any) as Vehicle;
  const createSectionFields = useCreateSectionsFieldsFn();
  const sectionFields = useMemo(() => createSectionFields(), [createSectionFields]);

  console.log(sectionFields, 'sectionFields');

  const initialValues = useMemo(
    () => ({
      seller_id: null,
      vin: existingVehicle?.vin || '',
      year: existingVehicle?.year || '',
      make: existingVehicle?.make || '',
      model: existingVehicle?.model || '',
      status: existingVehicle?.status || '',
      trim: existingVehicle?.trim || '',
      engine: existingVehicle?.engine || '',
      ext_color: existingVehicle?.ext_color || '',
      drive_train: existingVehicle?.drive_train || '',
      transmission: existingVehicle?.transmission || '',
      starting_bid: existingVehicle?.starting_bid || 100,
      dealer_comments: existingVehicle?.dealer_comments || '',
      reserve_price: existingVehicle?.reserve_price || null,
      options: existingVehicle?.options || [],
      is_guaranteed: existingVehicle?.is_guaranteed || false,
      auction_type: 'autoaxess',
      mileage: existingVehicle?.mileage,
      bluebook_mileage: existingVehicle?.bluebook_mileage,
      bluebook_value: existingVehicle?.bluebook_value,
      bluebook_auction_value_good: existingVehicle?.bluebook_auction_value_good,
      bluebook_mileage_adjustment: existingVehicle?.bluebook_mileage_adjustment,
      ...sectionFields
    }),
    [existingVehicle, sectionFields]
  );

  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true }}
    >
      {() => (
        <Wrapper>
          <Header />
          <Controller />
        </Wrapper>
      )}
    </Form>
  );
};

const Controller = () => {
  const searchParams = useSearchParams();
  const currentViewId = searchParams.get('view') || 'sections_list';

  const RenderView = currentViewId in viewMap ? viewMap[currentViewId] : () => null;

  return <RenderView />;
};

export default ListerVehicleUpload;
