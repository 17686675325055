import { Vehicle } from '../../../types/vehicle';
import styled from 'styled-components';
import FormattedAmount from '../../FormattedAmount';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase, DialogContent, Icon } from '@material-ui/core';
import { cutTextElipsis } from '../../../utils';
import {
  OfferLogData,
  OfferLogOperationType,
  OfferType,
  useGetOffersLogQuery
} from '../../../services/api/admin-auction-vehicles';
import { loginAsUser } from '../../../actions';
import Tooltip from '../../Tooltip';
import { push } from 'connected-react-router';
import { LogFilters } from './components/LogFilters';
import { setPage } from '../../../services/offerLogSlice/offerLogSlice';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {
  auctionId: number;
  closeModal: () => void;
};

const DialogContentSC = styled(DialogContent)`
  height: calc(100vh - 96px - 48px - 60px - 40px);
`;

const UpArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11 20V7.825L5.4 13.425L4 12L12 4L20 12L18.6 13.425L13 7.825V20H11Z" fill="#757575" />
  </svg>
);

const DownArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M13 4L13 16.175L18.6 10.575L20 12L12 20L4 12L5.4 10.575L11 16.175L11 4L13 4Z" fill="#757575" />
  </svg>
);

const Cross = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M6.22188 18.8258L5.17188 17.7758L10.9469 12.0008L5.17188 6.22578L6.22188 5.17578L11.9969 10.9508L17.7719 5.17578L18.8219 6.22578L13.0469 12.0008L18.8219 17.7758L17.7719 18.8258L11.9969 13.0508L6.22188 18.8258Z"
      fill="#212121"
    />
  </svg>
);

const PageWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const HeaderText = styled.div`
  color: #000;
  font-size: 22px;
  font-weight: 500;
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 0.75fr 1fr 2fr 1.5fr 1fr 1.5fr 1fr 160px;
  grid-template-rows: 1fr;
  color: #757575;
  font-size: 14px;
  padding: 10px 32px 10px 38px;
`;

const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TableRow = styled.div<{ background: string; borderColor: string }>`
  display: grid;
  grid-template-columns: 0.75fr 1fr 2fr 1.5fr 1fr 1.5fr 1fr 160px;
  grid-template-rows: auto;
  border-radius: 6px;
  background: ${props => props.background};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-left: ${props => `6px solid ${props.borderColor}`};
  padding: 10px 32px;
  min-height: 60px;
  color: #000;
  font-size: 14px;
`;

const VehicleDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VehicleDetailsTitle = styled.div`
  color: #225a91;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const VehicleDetailsVin = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 20px;
`;

const FromToSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  justify-content: center;
`;

const SoldLabel = styled.div`
  font-weight: 500;
`;

const SellerName = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
`;

const BuyerName = styled.div`
  cursor: pointer;
`;

const AmountSectionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  left: -40px;
`;

const CenteredText = styled.div`
  display: flex;
  align-items: center;
`;

const IconsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
`;

const NoContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 150px;
`;

const formatTimestamp = (timestamp: string | moment.Moment) => {
  return moment(timestamp).format('ddd DD.MM.YY h:mm A');
};

const isCarSold = (operationType: OfferLogOperationType) => {
  return [
    OfferLogOperationType.AdminApproved,
    OfferLogOperationType.AdminAcceptedFromTopBuyer,
    OfferLogOperationType.TopBuyerAccepted,
    OfferLogOperationType.SellerAcceptedFromTopBuyer,
    OfferLogOperationType.BuyerAccepted,
    OfferLogOperationType.AdminAwarded,
    OfferLogOperationType.SoldWithReserve
  ].includes(operationType);
};

const isBidOperation = (operationType: OfferLogOperationType) => {
  return [
    OfferLogOperationType.BuyerPlacedBid,
    OfferLogOperationType.BuyerPlacedBidAndReserveMet,
    OfferLogOperationType.AdminDeletedBid,
    OfferLogOperationType.AdminDeletedProxy,
    OfferLogOperationType.AdminModifiedBid,
    OfferLogOperationType.AdminModifiedProxy
  ].includes(operationType);
};

const ContentRow = ({ item, i }: { item: OfferLogData; i: number }) => {
  const rowStyleConfig = (() => {
    if (isCarSold(item.operation_type)) {
      return {
        background: 'rgba(253, 183, 20, 0.30)',
        borderColor: '#CA8D57'
      };
    }

    if (isBidOperation(item.operation_type)) {
      return {
        background: item.is_losing_bid ? '#B0B0B04D' : '#fff',
        borderColor: '#479747'
      };
    }

    if (item.operation_type === OfferLogOperationType.AdminPulled) {
      return {
        background: '#DDCFCD',
        borderColor: '#B4251E'
      };
    }

    if (item.operation_type === OfferLogOperationType.RelistVehicle) {
      return {
        background: '#fff',
        borderColor: '#3994DE'
      };
    }

    if (item.operation_type === OfferLogOperationType.UnawardVehicle) {
      return {
        background: '#fff',
        borderColor: '#CA8D57'
      };
    }

    if (item.is_losing_bid) {
      return {
        background: '#B0B0B04D',
        borderColor: '#757575'
      };
    }

    return {
      background: '#fff',
      borderColor: '#000'
    };
  })();
  return (
    <TableRow {...rowStyleConfig}>
      <CenteredText style={{ fontWeight: 500 }}>{i}</CenteredText>
      <CenteredText>{item.order_index || '-'}</CenteredText>
      <CarDetails vehicle={item.vehicle} />
      <FromToSection item={item} />
      <SellerSection item={item} />
      <BuyerSection item={item} />
      <AmountSection item={item} />
      <CenteredText>{formatTimestamp(item.created_at)}</CenteredText>
    </TableRow>
  );
};

const header = ['Number', 'Vehicle #', 'Car Details', 'From To', 'Seller', 'Buyer', 'Bid/Offer', 'Date'];

const RenderContent = ({ auctionId }: { auctionId: number }) => {
  const { filters, page, perPage } = useSelector((state: any) => state.offerLog);
  // const { id: auctionId } = useParams<{ id: string }>();
  const { data, isLoading, isFetching } = useGetOffersLogQuery({
    auctionId,
    sellerId: filters.seller?.value,
    buyerId: filters.buyer?.value,
    orderIndex: filters.orderIndex,
    vin: filters.vin,
    page,
    perPage
  });

  const offerLogRecords = useMemo(() => data?.rows?.ids?.map((id: any) => data?.rows?.entities?.[id]) ?? [], [
    data
  ]) as OfferLogData[];
  const offerLogCount = useMemo(() => data?.count ?? 0, [data]);

  const dispatch = useDispatch();
  const loadMore = useCallback(
    (...args: any[]) => {
      console.log('load more', args);
      if (isFetching || isLoading) {
        return;
      }
      console.log('load more called', page);
      dispatch(setPage(page + 1));
    },
    [dispatch, isFetching, isLoading, page]
  );

  if (offerLogRecords.length === 0) {
    return <NoContent>No logs found</NoContent>;
  }

  return (
    <DialogContentSC id="dialog-content">
      <TableHeader>
        {header.map(el => (
          <span key={el}>{el}</span>
        ))}
      </TableHeader>
      <InfiniteScroll
        scrollableTarget="dialog-content"
        dataLength={offerLogRecords.length}
        hasMore={!isFetching && page * perPage <= offerLogCount}
        next={loadMore}
        loader={<div className="loader" key={0} />}
      >
        <TableContent>
          {offerLogRecords?.map((item: any, i: any) => (
            <ContentRow key={item.id} i={offerLogCount - i} item={item} />
          ))}
        </TableContent>
      </InfiniteScroll>
    </DialogContentSC>
  );
};

export const OfferLog = ({ auctionId, closeModal }: Props) => {
  const { filters, page, perPage } = useSelector((state: any) => state.offerLog);
  const { isLoading } = useGetOffersLogQuery({
    auctionId,
    sellerId: filters.seller?.value,
    buyerId: filters.buyer?.value,
    orderIndex: filters.orderIndex,
    vin: filters.vin,
    page,
    perPage
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(1));
  }, [dispatch]);

  if (isLoading) return null;

  return (
    <PageWrapper>
      <HeaderWrapper>
        <HeaderRowWrapper>
          <HeaderText>Offers Log</HeaderText>
          <IconsWrapper>
            <CrossIconWrapper onClick={closeModal}>
              <Cross />
            </CrossIconWrapper>
          </IconsWrapper>
        </HeaderRowWrapper>
        <LogFilters auctionId={auctionId} />
      </HeaderWrapper>
      <TableWrapper>
        <RenderContent auctionId={auctionId} />
      </TableWrapper>
    </PageWrapper>
  );
};

const FromToSection = ({ item }: { item: OfferLogData }) => {
  const hasSoldSection = isCarSold(item.operation_type);

  const statusToText = {
    [OfferLogOperationType.BuyerToSeller]:
      item.offer_type && item.offer_type === OfferType.AdminPlacedOfferForTopBuyer
        ? `AA (${item.author.first_name}) placed an offer for Top buyer`
        : 'Top Buyer to Seller',
    [OfferLogOperationType.SellerToBuyer]: 'Seller to Top Buyer',
    [OfferLogOperationType.AdminToBuyer]: `AA (${item.author.first_name}) to Top Buyer`,

    [OfferLogOperationType.TopBuyerAccepted]: 'Top Buyer accepted',
    [OfferLogOperationType.SellerAcceptedFromTopBuyer]: 'Seller accepted Top Buyer’s offer',
    [OfferLogOperationType.AdminAcceptedFromTopBuyer]: `AA (${item.author.first_name}) accepted Top Buyer’s offer`,

    [OfferLogOperationType.SellerToAdmin]: 'Seller to AA',
    [OfferLogOperationType.AdminToBuyers]: `AA (${item.author.first_name}) to Buyers`,
    [OfferLogOperationType.BuyerPlacedOffer]:
      item.offer_type && item.offer_type === OfferType.AdminPlacedOfferForBuyer
        ? `AA (${item.author.first_name}) placed an offer for buyer`
        : 'Buyer placed offer',
    [OfferLogOperationType.SellerAccepted]: 'Seller accepted',
    [OfferLogOperationType.AdminApproved]: `AA (${item.author.first_name}) approved`,
    [OfferLogOperationType.BuyerAccepted]: 'Buyer accepted',

    [OfferLogOperationType.AdminPulled]: `AA (${item.author.first_name}) pulled`,
    [OfferLogOperationType.AdminStoppedCounter]: `AA (${item.author.first_name}) stopped countering`,
    [OfferLogOperationType.AdminAwarded]: `AA (${item.author.first_name}) awarded`,
    [OfferLogOperationType.UnawardVehicle]: `AA (${item.author.first_name}) Unawarded`,
    [OfferLogOperationType.RelistVehicle]: `AA (${item.author.first_name}) Relisted ${
      item.relisted_from_auction_id && item.relisted_from_auction_id !== item.auction_id
        ? `from Auction #${item.relisted_from_auction_id}`
        : ''
    }`,
    [OfferLogOperationType.RelistVehicleInto]: `AA (${item.author.first_name}) Relisted ${
      item.relisted_into_auction_id && item.relisted_into_auction_id !== item.auction_id
        ? `into Auction #${item.relisted_into_auction_id}`
        : ''
    }`,

    [OfferLogOperationType.SoldWithReserve]: 'Reserve price met',
    [OfferLogOperationType.AdminEditedOffer]: `AA (${item.author.first_name}) edited offer`,
    [OfferLogOperationType.AdminExtended]: `AA (${item.author.first_name}) extended`,

    [OfferLogOperationType.AdminPlacedBid]: `AA (${item.author.first_name}) Placed Bid`,
    [OfferLogOperationType.AdminPlacedFakeBid]: `AA (${item.author.first_name}) Placed Bid`,

    [OfferLogOperationType.VehicleListed]:
      item.author.id === item.seller_id
        ? `Seller Listed the vehicle`
        : `AA (${item.author.options?.name_for_bio ?? ''})`,
    [OfferLogOperationType.BuyerPlacedBid]: `Buyer Placed Bid`,
    [OfferLogOperationType.BuyerPlacedBidAndReserveMet]: `Buyer placed bid`,
    [OfferLogOperationType.AdminDeletedBid]: `AA (${item.author.first_name}) Deleted bid`,
    [OfferLogOperationType.AdminDeletedProxy]: `AA (${item.author.first_name}) Deleted proxy`,
    [OfferLogOperationType.AdminModifiedBid]: `AA (${item.author.first_name}) Modified bid`,
    [OfferLogOperationType.AdminModifiedProxy]: `AA (${item.author.first_name}) Modified proxy`,
    [OfferLogOperationType.AdminDeletedAllBids]: `AA (${item.author.first_name}) Deleted all bids`,

    [OfferLogOperationType.AdminUnlockedOrder]: `AA (${item.author.first_name}) Unlocked numbers`,
    [OfferLogOperationType.AdminLockedOrder]: `AA (${item.author.first_name}) Locked numbers`
  };

  return (
    <FromToSectionWrapper>
      {hasSoldSection && <SoldLabel>SOLD</SoldLabel>}
      <span>{statusToText[item.operation_type]}</span>
      {item.operation_type === OfferLogOperationType.BuyerPlacedBidAndReserveMet && <span>(Reserve price met)</span>}
      {item.operation_type === OfferLogOperationType.VehicleListed && item.author.id !== item.seller_id && (
        <span>Listed the vehicle</span>
      )}
    </FromToSectionWrapper>
  );
};

const CarDetails = ({ vehicle }: { vehicle: Vehicle }) => {
  const dispatch = useDispatch();

  if (!vehicle) {
    return <CenteredText>{'-'}</CenteredText>;
  }

  const navigateToInnerPage = () => {
    dispatch(push(`/vehicles/${vehicle.id}/view`));
  };

  return (
    <VehicleDetailsWrapper>
      <VehicleDetailsTitle onClick={navigateToInnerPage}>
        {vehicle.year} {vehicle.make} {vehicle.model}
      </VehicleDetailsTitle>
      <VehicleDetailsVin>{vehicle.vin}</VehicleDetailsVin>
    </VehicleDetailsWrapper>
  );
};

const SellerSection = ({ item }: { item: OfferLogData }) => {
  const dispatch = useDispatch();
  const handleNameClicked = () => {
    dispatch(
      loginAsUser({ id: item.seller_id, initialPage: `/admin/auctions/${item.auction_id}?log_opened=true` }).request
    );
  };

  if (!item.seller?.dealership_name) return <CenteredText>-</CenteredText>;

  return (
    <Tooltip title={item.seller.dealership_name} isTextOverflown={item.seller.dealership_name.length >= 12}>
      <CenteredText>
        <SellerName onClick={handleNameClicked}>{cutTextElipsis(item.seller.dealership_name, 12)}</SellerName>
      </CenteredText>
    </Tooltip>
  );
};

const companyToLabel: Record<string, string> = {
  acv: 'ACV',
  other: 'Other',
  open_lane: 'OL'
};

const BuyerSection = ({ item }: { item: OfferLogData }) => {
  const dispatch = useDispatch();
  const handleNameClicked = () => {
    if (!item.buyer_id || item.buyer?.is_bot || item.operation_type === OfferLogOperationType.AdminPlacedFakeBid) {
      return;
    }

    dispatch(
      loginAsUser({ id: item.buyer_id, initialPage: `/admin/auctions/${item.auction_id}?log_opened=true` }).request
    );
  };

  if (!item.buyer?.dealership_name) return <CenteredText>-</CenteredText>;

  const parsedName = (() => {
    if (item.operation_type === OfferLogOperationType.AdminPlacedFakeBid || item.buyer?.is_bot) {
      return `${companyToLabel[item.buyer.company_name]} - ${item.buyer.dealership_name}`;
    }

    return item.buyer.dealership_name;
  })();

  return (
    <Tooltip title={parsedName} isTextOverflown={parsedName.length >= 12}>
      <CenteredText>
        <BuyerName onClick={handleNameClicked}>{cutTextElipsis(parsedName, 12)}</BuyerName>
      </CenteredText>
    </Tooltip>
  );
};

const AmountSection = ({ item }: { item: OfferLogData }) => {
  if (!item.amount) return <CenteredText>-</CenteredText>;

  const ArrowComponent = (() => {
    if (!item.offer_amount_type) return () => null;
    if (item.offer_amount_type === 'upgrade') return UpArrow;
    if (item.offer_amount_type === 'downgrade') return DownArrow;
    return () => null;
  })();
  return (
    <AmountSectionWrapper>
      <ArrowWrapper>
        <ArrowComponent />
      </ArrowWrapper>
      <FormattedAmount amount={item.amount} />
      {(item.proxy_amount || item.previous_amount) && <span> / </span>}
      {item.proxy_amount && <FormattedAmount amount={item.proxy_amount} />}
      {item.previous_amount && (
        <s>
          <FormattedAmount amount={item.previous_amount} />
        </s>
      )}
    </AmountSectionWrapper>
  );
};
