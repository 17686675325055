import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Input } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { SectionDivider, SectionTitle, CounterSection } from '../../components';
import { modalsToggle, adminSetCounter } from '../../../../../actions';
import { ADMIN_VEHICLE_STATUS, AUCTION_STATUSES } from '../../../../../constants';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import { ActiveOfferInfo, OfferHistory } from '../../../../../components/Offers';
import { legacyOfferAuctionSelector } from 'services/api/offer-auction';
import { OfferAuctionStatus, OfferStatus } from '../../../../../types/vehicle';
import { TimerBlock } from '../TimerBlock';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { ControlsComponent } from './ControlsComponent';

const modalTitle = 'confirmCounterModal';

const StyledGrid2 = styled(Grid)`
  padding: 0 32px 0 0;
`;
const StyledBold = styled.span`
  font-weight: 500;
  font-size: 18px;
`;

const CounterButton = styled(Button)`
  background-color: #3994de;

  &:hover {
    background-color: #3994de;
  }
`;

const parseAdminAuctionOfferInfo = offerAuction => {
  const res = {
    sellerAmount: 0,
    buyerAmount: 0,
    adminAmount: 0,
    activeOffer: null
  };
  if (!offerAuction) return res;
  if (offerAuction.status === OfferAuctionStatus.PRIVATE_BIDS) {
    const buyerAmount = offerAuction.privateOffers?.find(offer => offer.sender.role === 'buyer')?.amount;
    const sellerAmount = offerAuction.privateOffers?.find(offer => !offer.is_admin && offer.sender.role === 'seller')
      ?.amount;
    const adminAmount = offerAuction.privateOffers?.find(offer => offer.is_admin)?.amount;

    res.buyerAmount = buyerAmount ?? res.buyerAmount;
    res.sellerAmount = sellerAmount ?? res.sellerAmount;
    res.adminAmount = adminAmount ?? res.adminAmount;
    res.activeOffer = offerAuction.privateOffers?.find(offer => offer.status === OfferStatus.SENT);
  }
  return res;
};

class CounterBidBlock extends Component {
  state = {
    counterAmount: ''
  };

  handleChangeAmount = e => {
    this.setState({ counterAmount: e.target.value });
  };

  handleClickCounter = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalTitle);
  };

  handleCounter = () => {
    const { currentVehicle, modalsToggle: toggle, adminSetCounterBid } = this.props;
    const { counterAmount } = this.state;
    adminSetCounterBid({
      vehicle_id: currentVehicle.id,
      amount: counterAmount.toString().replace(/[\D]/g, '')
    });
    toggle(modalTitle);
  };

  renderCounteringControls = () => {
    const { currentVehicle } = this.props;
    return (
      <>
        <CounterSection vehicle={currentVehicle} text={'Counter the Highest Bid'} />
        <SectionDivider />
      </>
    );
  };

  render() {
    const { isVehicleTimerEnded, currentVehicle, offerAuction, user } = this.props;
    const { sellerAmount, adminAmount, buyerAmount, activeOffer } = parseAdminAuctionOfferInfo(offerAuction);
    const status = auctionOfferStatuses(currentVehicle, offerAuction, user);
    const isAuctionActive = currentVehicle.auction.status !== AUCTION_STATUSES.ENDED;
    const isBotWinner = currentVehicle.highest_bid?.bidder?.is_bot;

    return (
      <>
        {isVehicleTimerEnded &&
          isAuctionActive &&
          status !== ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT &&
          !isBotWinner &&
          this.renderCounteringControls()}

        <ActiveOfferInfo offerAuction={offerAuction} vehicle={currentVehicle} />

        {Boolean(sellerAmount || adminAmount || buyerAmount) && (
          <Grid item xs={12}>
            <SectionDivider />
          </Grid>
        )}
        {status === ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT && <ControlsComponent vehicle={currentVehicle} />}
        <OfferHistory offerAuction={offerAuction} />
        {Boolean(offerAuction?.privateOffers?.length) && (
          <Grid item xs={12}>
            <SectionDivider />
          </Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.vehicles.loading,
  currentVehicle: state.vehicles.selectedVehicle.data,
  offerAuction: legacyOfferAuctionSelector(state, state.vehicles.selectedVehicle.data.id),
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  modalsToggle: key => dispatch(modalsToggle(key)),
  adminSetCounterBid: data => dispatch(adminSetCounter(data).request)
});

export default connect(mapStateToProps, mapDispatchToProps)(CounterBidBlock);
